import { AuthorizationUser } from "./enum"
import { IBrands } from "./interfaces"

export const OpportunityMap = [
	{ key: "name", value: "Nome da Oportunidade" },
	{ key: "acceptedTerm", value: "Termo de aceite" },
	{ key: "cnpj", value: "CNPJ" },
	{ key: "contact", value: "Nome de contato do cliente" },
	{ key: "phone", value: "Telefone do cliente" },
	{ key: "email", value: "E-mail do cliente" },
	{ key: "network", value: "O cliente faz parte de alguma rede ou grupo?" },
	{ key: "aplication", value: "Aplicação" },
	{ key: "specification", value: "Especificação Tecnica Solicitada" },
	{ key: "homologated", value: "Já foi feito homologação?" },
	{
		key: "haveCollector",
		value: "O cliente possui algum equipamento semelhante?",
	},
	{ key: "partner", value: "Parceiro de software" },
	{
		key: "opportunityType",
		value: "Selecione o tipo de órgão relacionado à sua oportunidade",
	},
	{ key: "opportunityTypeStatus", value: "Qual é o andamento do seu projeto?" },

	{ key: "conclusionDate", value: "Data prevista para conclusão" },
	{ key: "opportunityOrigin", value: "Origem da oportunidade" },
	{ key: "probabilityClosing", value: "Qual é a situação da oportunidade?" },
	{ key: "seller", value: "Vendedor" },
	{ key: "approveDate", value: "Data de aprovação" },
]

export const OpportunityProductMap = [
	{ key: "partNumber", value: "Part Number" },
	{ key: "description", value: "Descrição" },
	{ key: "amount", value: "Quantidade" },
]

export const OpportunityExcellMap = [
	{ key: "name", value: "Nome da Oportunidade" },
	{ key: "cep", value: "CEP" },
	{ key: "id", value: "ID" },
	{ key: "_id", value: "_ID" },
	{ key: "fantasyName", value: "Nome Fantasia" },
	{ key: "corporateName", value: "Razão Social" },
	{ key: "street", value: "Rua" },
	{ key: "state", value: "Estado" },
	{ key: "city", value: "Cidade" },
	{ key: "country", value: "País" },
	{ key: "cnpj", value: "CNPJ" },
	{ key: "reason", value: "Razão da reprovação" },
	{ key: "contact", value: "Nome de contato do cliente" },
	{ key: "phone", value: "Telefone do cliente" },
	{ key: "email", value: "E-mail do cliente" },
	{ key: "network", value: "O cliente faz parte de alguma rede ou grupo?" },
	{ key: "estimated", value: "Valor total da oportunidade" },
	{ key: "status", value: "Status da oportunidade" },
	{ key: "aplication", value: "Aplicação do projeto" },
	{ key: "specification", value: "Especificação Tecnica Solicitada" },
	{ key: "homologated", value: "Já foi feito homologação?" },
	{ key: "renovationDate", value: "Data de renovação" },
	{ key: "isDeleted", value: "Oportunidade deletada?" },
	{ key: "seller", value: "Vendedor responsável" },
	{ key: "register", value: "ID da oportunidade no CRM" },
	{ key: "date", value: "Data de criação" },
	{ key: "haveCollectorDescription", value: "Qual equipamento?" },
	{ key: "additionalInformation", value: "Informações adicionais" },
	{ key: "resale", value: "Revenda" },
	{ key: "representative", value: "Gerente" },
	{
		key: "haveCollector",
		value: "O cliente possui algum equipamento semelhante?",
	},
	{ key: "partner", value: "Parceiro de software" },
	{ key: "partnerOwner", value: "Quem é o proprietário do software?" },
	{ key: "partnerName", value: "Qual é o seu parceiro de software?" },
	{ key: "conclusionDate", value: "Data prevista para conclusão" },
	{ key: "opportunityOrigin", value: "Origem da oportunidade" },
	{ key: "partNumber", value: "Part Number" },
	{ key: "description", value: "Descrição" },
	{ key: "amount", value: "Quantidade" },
	{ key: "price", value: "Valor" },
	{ key: "accessory", value: "É um acessório?" },
	{ key: "competition", value: "É um produto concorrente?" },
	{ key: "probabilityClosing", value: "Qual é a situação da oportunidade?" },
	{ key: "approveDate", value: "Data de aprovação" },
]

export const BrandsMap: { [brand: string]: IBrands } = {
	Bluebird: {
		logo: `/images/LOGO-BLUEBIRD.png`,
		alt: "Logo Bluebird",
		color: "00AEEF",
	},
	Chainway: {
		logo: `/images/LOGO-CHAINWAY.png`,
		alt: "Logo Chainway",
		color: "191B1C",
	},
	Tas: {
		logo: `/images/LOGO-TAS.png`,
		alt: "Logo Tas",
		color: "026ED2",
	},
	Sewoo: {
		logo: `/images/LOGO-SEWOO.png`,
		alt: "Logo Sewoo",
		color: "005EAD",
	},
	Mobilebase: {
		logo: `/images/LOGO-MOBILEBASE.png`,
		alt: "Logo Mobilebase",
		color: "01246b",
	},
	H3C: {
		logo: `/images/LOGO-H3C.png`,
		alt: "Logo H3C",
		color: "e60012",
	},
	Pointmobile: {
		logo: `/images/LOGO-POINTMOBILE.png`,
		alt: "Logo Point Mobile",
		color: "B93920",
	},
	Cass: {
		logo: `/images/LOGO-CASS.png`,
		alt: "Logo Cass",
		color: "3c2154",
	},

	TSC: {
		logo: `/images/LOGO-TSC.png`,
		alt: "Logo TSC",
		color: "134D98",
	},

	Unitech: {
		logo: `/images/LOGO-UNITECH.png`,
		alt: "Logo UNITECH",
		color: "005EAC",
	},
	IData: {
		logo: `/images/LOGO-IDATA.png`,
		alt: "Logo IDATA",
		color: "005EAC",
	},
}

export const opportunityStatus = [
	{ key: "analysis", value: "Análise" },
	{ key: "disapproved", value: "Desaprovado" },
	{ key: "approved", value: "Aprovado" },
	{ key: "renovation", value: "Em renovação" },
	{ key: "awaiting_renovation", value: "Aguardando Renovação" },
	{ key: "expired", value: "Expirada" },
]

export const resaleStatus = [
	{ key: "analysis", value: "Análise" },
	{ key: "disapproved", value: "Desaprovado" },
	{ key: "approved", value: "Aprovado" },
	{ key: "renovation", value: "Em renovação" },
	{ key: "awaiting_renovation", value: "Aguardando Renovação" },
	{ key: "expired", value: "Expirada" },
]

export const actionState = [
	{ key: "create", value: "Criação" },
	{ key: "update", value: "Atualização" },
]

export const probabilityClosing = [
	{ key: "25", value: "0% - 49%" },
	{ key: "50", value: "50% - 70%" },
	{ key: "75", value: "71% - 99%" },
	{ key: "100", value: "100%" },
]

export const Permissions = [
	{ key: AuthorizationUser.REPRESENTATIVE, value: "Representante" },
	{ key: AuthorizationUser.RESALE, value: "Revenda" },
	{ key: AuthorizationUser.SELLER, value: "Vendedor" },
	{ key: AuthorizationUser.SUPPORT, value: "Suporte" },
]

export const UserIcones = [
	{
		key: AuthorizationUser.REPRESENTATIVE,
		value: "card-account-details-star-outline",
	},
	{ key: AuthorizationUser.RESALE, value: "storefront-outline" },
	{ key: AuthorizationUser.SELLER, value: "account-tie-outline" },
	{ key: AuthorizationUser.SUPPORT, value: "face-agent" },
]
